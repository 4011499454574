import React from 'react';
import Layout from '@lekoarts/gatsby-theme-cara/src/components/layout';
import MenuBar from '../components/menu-bar';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from '../constants/theme.js';
import SEO from '@lekoarts/gatsby-theme-cara/src/components/seo';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import BlogBackground from '../components/blog-background';
import GenericFooter from '../components/generic-footer';
import Share from '../components/share';

const BlogTemplate = ({ data: { mdx, file, site } }) => (
  <ThemeProvider theme={theme}>
    <SEO
      title={mdx.frontmatter.title}
      description={mdx.excerpt}
      pathname={mdx.fields.slug}
      image={file.relativePath}
    />
    <Layout className="Blog-post-page">
      <MenuBar />
      <BlogBackground offset={0} />
      <div className="Blog-post-content">
        <h1>{mdx.frontmatter.title}</h1>
        {/* <p>Published on {mdx.frontmatter.date}</p>
        {mdx.frontmatter?.author && <p>By {mdx.frontmatter.author}</p>} */}
        <Img fluid={file.childImageSharp.fluid} className="centered-image" />
        <br />
        <br />
        {mdx.frontmatter.photoCredit && <p>Photo by {mdx.frontmatter.photoCredit}</p>}
        <article className="styled-markdown">
          <MDXRenderer>{mdx.body}</MDXRenderer>
        </article>
        <Share
          socialConfig={{
            config: {
              url: `${site.siteMetadata.siteUrl}${mdx.fields.slug}`,
              title: mdx.frontmatter.title,
            },
          }}
          tags={mdx.frontmatter.tags}
        />
      </div>
      <GenericFooter />
    </Layout>
  </ThemeProvider>
);

export default BlogTemplate;

export const pageQuery = graphql`
  query BlogPostQuery($id: String, $slug: String) {
    site {
      siteMetadata {
        siteUrl
        author
      }
    }
    mdx(id: { eq: $id }) {
      id
      body
      excerpt
      fields {
        slug
      }
      frontmatter {
        title
        author
        date
        photoCredit
        tags
      }
    }
    file(relativePath: { regex: $slug }) {
      relativePath
      childImageSharp {
        fluid(maxWidth: 1200, maxHeight: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

/** @jsx jsx */

import React from 'react';
import { Footer as ThemeFooter, jsx } from 'theme-ui';
import styled from '@emotion/styled';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Footer from './footer';
import { waveAnimation } from '@lekoarts/gatsby-theme-cara/src/styles/animations';

const InnerWave = styled.div`
  path {
    ${waveAnimation(`20s`)};
  }
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'absolute',
      width: '100%',
      marginTop: '150px',
    },
  }),
);

const GenericFooter = () => {
  const classes = useStyles();
  return (
    <div className="Generic-footer-container">
      <div
        sx={{
          width: `full`,
          transform: `matrix(1, 0, 0, -1, 0, 0) translate(0, -200px)`,
        }}
      >
        <InnerWave
          sx={{
            position: `relative`,
            height: `full`,
            svg: { width: `100%`, height: `40vh` },
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            id="contact-wave"
            viewBox="0 0 800 338.05"
            preserveAspectRatio="none"
          >
            <path fill="#2D3748">
              <animate
                attributeName="d"
                values="M 0 100 Q 250 50 400 200 Q 550 350 800 300 L 800 0 L 0 0 L 0 100 Z;M 0 100 Q 200 150 400 200 Q 600 250 800 300 L 800 0 L 0 0 L 0 100 Z;M 0 100 Q 150 350 400 200 Q 650 50 800 300 L 800 0 L 0 0 L 0 100 Z"
                repeatCount="indefinite"
                dur="30s"
              />
            </path>
          </svg>
        </InnerWave>
      </div>
      <Footer isGeneric={true} />
    </div>
  );
};

export default GenericFooter;

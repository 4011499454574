/** @jsx jsx */
import { jsx } from 'theme-ui';
import { UpDown, UpDownWide } from '@lekoarts/gatsby-theme-cara/src/styles/animations';
import SVG from '@lekoarts/gatsby-theme-cara/src/components/svg';

const BlogBackground = ({ offset }: { offset: number }) => {
  return (
    <div className="Blog-post-header">
      <UpDown sx={{ maxHeight: '20vh' }}>
        <SVG icon="cone" hiddenMobile width={24} color="#6FFFE9" left="80%" top="10%" />
        <SVG icon="cone" width={6} color="#6FFFE9" left="60%" top="15%" />
      </UpDown>
      <UpDownWide sx={{ maxHeight: '20vh' }}>
        <SVG
          icon="arrowUp"
          hiddenMobile
          width={16}
          color="icon_blue"
          left="60%"
          top="15%"
        />

        <SVG icon="spiral" width={24} stroke color="icon_pink" left="28%" top="15%" />

        <SVG icon="triangle" width={6} color="icon_pink" stroke left="75%" top="10%" />
        <SVG
          icon="upDown"
          hiddenMobile
          width={8}
          color="icon_darkest"
          left="45%"
          top="10%"
        />
      </UpDownWide>

      <SVG icon="circle" width={6} color="icon_darkest" left="4%" top="20%" />

      <SVG icon="triangle" width={8} stroke color="icon_darker" left="25%" top="5%" />

      <SVG icon="box" width={6} color="icon_darkest" left="10%" top="10%" />
      <SVG icon="cone" width={12} color="#6FFFE9" left="40%" top="30%" />
    </div>
  );
};

export default BlogBackground;
